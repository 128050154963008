/**
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../common/utils';

.Home-EmulatorCard {
  h4 {
    margin-bottom: 0;
  }

  .title {
    align-items: center;
    display: flex;
    margin-top: 0;
    line-height: 1.2em; // Shrink a bit, but not too much that text cuts off.

    > span {
      @include ellipsis();
    }

    .rmwc-icon {
      margin-right: 8px;
    }
  }
}

.Home-EmulatorCard-Info {
  margin: 16px 24px;
}
