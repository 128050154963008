/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.storageHeaderWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  width: 100%;
}
.breadcrumbWrapper {
  overflow: auto;
  flex-basis: 100%;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  overflow: hidden;

  :global .InteractiveBreadCrumbBar {
    width: 100%;
  }
}

.storageHeaderActionBarWrapper {
  // remove box shadow over the side panel.
  :global .CardActionBar-container {
    box-shadow: none;
  }
}
