/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

$BORDER_RADIUS_CARD: 8px;
$BORDER_RADIUS_CARD_TOP: $BORDER_RADIUS_CARD $BORDER_RADIUS_CARD 0 0;
$BORDER_RADIUS_CARD_BOTTOM: 0 0 $BORDER_RADIUS_CARD $BORDER_RADIUS_CARD;
$BORDER_RADIUS_CARD_LEFT: $BORDER_RADIUS_CARD 0 0 $BORDER_RADIUS_CARD;
$BORDER_RADIUS_CARD_RIGHT: 0 $BORDER_RADIUS_CARD $BORDER_RADIUS_CARD 0;

$BORDER_RADIUS_BUTTON: 8px;
$BORDER_RADIUS_POPUP: 8px;
$BORDER_RADIUS_DIALOG: 16px;
$BORDER_RADIUS_INPUT: 4px;
