/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../../common/utils';

$list-item-padding: 16px;
$icon-size: 18px;
$inline-spacing: 8px;

.FieldPreview {
  > .FieldPreview-expand-icon {
    color: #757575;
    vertical-align: middle;
    margin-right: $inline-spacing;
  }

  &--primitive {
    .FieldPreview-expand-icon {
      visibility: hidden; // Hide it but keep it in layout to align with objs.
    }
  }

  &--expanded > .FieldPreview-summary {
    display: none;
  }

  .FieldPreview-summary {
    @include ellipsis;
    font-family: 'Roboto Mono', monospace;
  }

  .Constraint-hint {
    font-style: italic;
  }

  .Constraint-unknown {
    // Use a non-monospace format to make the greek letter x more stylistic.
    // Monospace ones look too much like English "x" (e.g. in Roboto Mono).
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-style: italic;
    font-weight: 500;
  }
}

.FieldPreview-children > .FieldPreview {
  padding-left: ($list-item-padding + $icon-size + $inline-spacing);

  .FieldPreview-children & {
    padding-left: ($list-item-padding + ($icon-size + $inline-spacing) * 2);
    .FieldPreview-children & {
      padding-left: ($list-item-padding + ($icon-size + $inline-spacing) * 3);
    }
  }
}

.FieldPreview-key {
  margin-right: $inline-spacing;
  font-family: 'Roboto Mono', monospace;
  white-space: pre;

  &::after {
    content: ':';
  }
  .FieldPreview--expanded > &::after {
    content: '';
  }
}

.FieldPreview-actions {
  display: flex;
  align-items: center;
}
