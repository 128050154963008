/**
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.Firestore-PanelHeader {
  .CardActionBar-container {
    padding: 8px 12px;
    height: 44px;
  }

  .CardActionBar-actions {
    // Icon buttons are padded, so we remove the double padding on the right.
    margin-right: -12px;
  }
}

.Firestore-PanelHeader-title {
  padding-left: 4px;
}
