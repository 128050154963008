/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.metadata {
  margin: 0;

  :global dd {
    margin: 0 0 16px 0;
  }

  :global dt {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.token {
  background: transparent;
  width: 100%;
  border: 0 solid;
  height: 20px;
}
