/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.accordion {
  border-top: 1px var(--fire-color-input-borders) solid;
  --spacing: var(--accordion-spacing, 40px);
}

.header {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 16px var(--spacing);
}

.header:focus {
  background: var(--fire-color-input-borders);
}

.content {
  padding: 8px var(--spacing) var(--spacing) var(--spacing);
}

.icon {
  padding: 0 12px;
}
