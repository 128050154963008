/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.DocumentEditor {
  overflow-y: auto;

  .Field {
    width: 136px;
  }

  .FieldSelect {
    width: 120px;
  }
}

.DocumentEditor-ArrayIndex .Field {
  width: 40px;
}

.DocumentEditor-Array,
.DocumentEditor-Map {
  grid-area: child;
}

.DocumentEditor-ArrayEntry,
.DocumentEditor-MapEntry {
  display: grid;
  grid-template-areas:
    'parent parent secondary action'
    '. child child child';
  grid-auto-columns: 32px max-content max-content min-content;
  align-items: center;
  grid-gap: 8px;
}

.DocumentEditor-ArrayEntryMetadata,
.DocumentEditor-MapEntryMetadata {
  display: inline-flex;
  grid-area: parent;
  align-items: center;
}

.DocumentEditor-ArrayEntries,
.DocumentEditor-MapEntries {
  grid-area: child;
}

.DocumentEditor-Primitive {
  grid-area: secondary;
}

.DocumentEditor-ArrayEntryDelete,
.DocumentEditor-MapEntryDelete {
  float: left;
  // Pull down to align with fields
  bottom: -8px;
}

.Document-TypeSymbol {
  margin-bottom: -16px;
  padding: 0 8px;
}
