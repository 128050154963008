/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import '../../../../../../components/common/color';

.actionHeaderWrapper {
  align-items: center;
  display: flex;
  padding: 0 12px;
  width: 100%;
}

.actionHeaderLabel {
  border-right: 1px rgba(var(--mdc-theme-primary-rgb), 0.12) solid;
  height: 32px;
  line-height: 32px;
  padding-right: 16px;
}

.actionBar :global .CardActionBar-container {
  background: var(--mdc-theme-primary);
}

// This button need a background of primary, and color of white.
// There no simple way to do this using theme providers.
.openButton {
  background: var(--mdc-theme-surface) !important;
  margin-right: 16px;

  &:disabled {
    background: $COLOR_WHITE_DISABLED;
    opacity: 0.3;
  }

  :global .mdc-button__label {
    color: var(--mdc-theme-primary) !important;
  }
}

.deleteButton {
  border-color: rgba(255, 255, 255, 0.3) !important;
}
