/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import './utils';

$QUANTUM_BLACK_ALPHA_06: rgba(0, 0, 0, 0.06);
$QUANTUM_BLACK_ALPHA_08: rgba(0, 0, 0, 0.08);
$QUANTUM_BLACK_ALPHA_12: rgba(0, 0, 0, 0.12); // e.g. dividers
$QUANTUM_BLACK_ALPHA_26: rgba(0, 0, 0, 0.26); // e.g. disabled
$QUANTUM_BLACK_ALPHA_54: rgba(0, 0, 0, 0.54);

$COLOR_WHITE_ALPHA_30: rgba(255, 255, 255, 0.3);
$COLOR_WHITE_ALPHA_87: rgba(255, 255, 255, 0.87);

$COLOR_PURE_BLACK: #000;
$COLOR_BLACK_SECONDARY: $QUANTUM_BLACK_ALPHA_54;
$COLOR_BLACK_ALPHA_06: $QUANTUM_BLACK_ALPHA_06;
$COLOR_BLACK_ALPHA_08: $QUANTUM_BLACK_ALPHA_08;
$COLOR_BLACK_ALPHA_12: $QUANTUM_BLACK_ALPHA_12;
$COLOR_BLACK_DISABLED: $QUANTUM_BLACK_ALPHA_26;

$COLOR_WHITE_PRIMARY: #fff;
$COLOR_WHITE_DISABLED: $COLOR_WHITE_ALPHA_30;

$COLOR_TEXT_PRIMARY: rgba(0, 0, 0, 0.87);
$COLOR_TEXT_SECONDARY: rgba(0, 0, 0, 0.54);
$COLOR_BACKGROUND_KEY: rgba(0, 0, 0, 0.08);
$COLOR_BACKGROUND_CODE: rgba(196, 196, 196, 0.24);

$COLOR_DIVIDER: $QUANTUM_BLACK_ALPHA_12;
