/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../../common/color';
@import '../../common/z';
@import 'common/mixins';

$name: 'NodeParent';

.NodeParent {
  position: relative;

  .#{$name}__tree-button {
    background-color: transparent;
    border: none;
    color: $COLOR_TEXT_SECONDARY;
    cursor: pointer;
    height: 32px;
    outline: none;
  }

  .#{$name}__label {
    @include hoverKey($name);
    align-items: center;
    color: $COLOR_TEXT_SECONDARY;
    display: flex;
    height: 32px; // to fit buttons

    > .NodeActions {
      display: none;
      margin-left: 12px;
    }

    &:hover > .NodeActions,
    .NodeActions--active {
      display: inline-block;
    }
  }

  .Firestore-InlineEditor-relative-anchor {
    left: 0;
    margin: 0 20px 0 36px;
    position: absolute;
    right: 0;
    top: 36px;

    .Firestore-InlineEditor {
      left: -8px;
      right: initial;
    }
  }

  .#{$name}__children-container {
    margin-left: 36px;
  }

  .#{$name}__children {
    list-style-position: inside;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .mdc-menu-surface--anchor {
    display: inline;
  }
}
form {
  display: block;
}
