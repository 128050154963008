/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.HighlightedJSON {
  background-color: #fff;
  overflow: auto;
  padding: 2px 0px 2px 3px;
  white-space: pre;
  grid-area: message;

  .line {
    div {
      display: inline;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    color: #aaa;

    .key {
      color: black;
      cursor: pointer;
    }
    .value {
      color: #005ae5;
      cursor: pointer;
    }
  }
}
