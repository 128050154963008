/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.Firestore-Requests-Copy-Path-Snackbar {
  & .mdc-snackbar__surface {
    background-color: #051e34; // navy800
  }
  i.rmwc-icon {
    // !important is needed because Snackbar sets an inline color style
    color: #00bfa5 !important; // successOnDark
  }
}
