/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../../../common/color';

.noResultsWrapper {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Double selector - for higher specificity to Override rmwc styles.
.tableWrapper.tableWrapper {
  // Avoid double borders.
  margin: -1px;
  border-radius: 0 0 4px 4px;
}

// TODO(kirjs): Consider using ThemeProvider instead.
.disabled {
  background: $COLOR_WHITE_DISABLED;
  opacity: 0.3;

  &:hover {
    // Override RMWC styles.
    background-color: transparent !important;
  }
}
