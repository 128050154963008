/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.tableWrapper {
  border-radius: 0 0 8px 8px;
  width: 100%;
  border-right: 0 solid;
  border-left: 0 solid;
  margin-bottom: -1px;

  :global .mdc-data-table__cell {
    // This allows for multiline file names. Taking height value from rmwc.
    height: auto;
    min-height: 52px;
  }
}

.link {
  text-decoration: underline;
}

.checkboxColumn {
  width: 64px;
}

.name {
  max-width: 50vw;
  display: flex;
  align-items: center;
}

.row {
  cursor: pointer;
}

.columnUploading {
  display: flex;
  justify-content: space-between;
  width: 110px;
}

.item {
  overflow-wrap: break-word;
  white-space: pre;
  width: 100%;
  padding: 12px 16px 12px 0;
}
