/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../common/z';

.InteractiveBreadCrumbBar {
  z-index: $ZINDEX_LOW; // show elevation shadow

  .InteractiveBreadCrumbBar-form {
    align-items: center;
    display: flex;
    padding-right: 8px;

    .InteractiveBreadCrumbBar-prefix {
      white-space: nowrap;
      font-family: 'Roboto Mono', monospace;
      font-size: 14px;
      line-height: 24px;
      padding: 16px 0 16px 20px;

      + .value .mdc-text-field__input {
        padding-left: 0;
      }
    }

    .value {
      flex: 1 auto;

      .mdc-text-field__input {
        border-bottom: none;
        font-size: 14px;
        line-height: 24px;
        padding-left: 16px;
      }

      .mdc-line-ripple {
        display: none;
      }
    }
  }

  .BreadCrumbs {
    flex: 1 auto;
  }
}
