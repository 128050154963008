/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@mixin icon($content, $size: 24px) {
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  content: $content;
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: $size;
  font-style: normal;
  font-weight: normal;
  left: 0;
  letter-spacing: normal;
  line-height: $size;
  position: absolute;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}
