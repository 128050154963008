/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import './color';
@import './radius';
@import './utils';

.Callout {
  display: flex;
}

.Callout-body {
  padding: 12px 24px;
}

.Callout-message {
  display: flex;
  align-items: stretch;
}

.Callout-icon {
  margin-right: 12px;
  pointer-events: none;
}

.Callout-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: flex-end;
  padding-right: 16px;

  .mdc-button {
    margin-left: 4px;
    padding: 0 8px;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }
  }
}

// situational overrides
.mdc-card > .Callout:first-child {
  border-top-left-radius: $BORDER_RADIUS_CARD;
  border-top-right-radius: $BORDER_RADIUS_CARD;
}

.mdc-card > .Callout:last-child {
  border-bottom-left-radius: $BORDER_RADIUS_CARD;
  border-bottom-right-radius: $BORDER_RADIUS_CARD;
}

.Callout-aside {
  background-color: var(--mdc-theme-background);
  color: var(--mdc-theme-text-primary-on-background);

  .Callout-icon {
    color: var(--mdc-theme-primary);
  }
}

.Callout {
  a,
  a:active,
  a:visited,
  a:hover {
    color: var(--mdc-theme-on-primary);
  }
}
