/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../common/radius';

.Database-Container {
  > .Callout {
    margin-bottom: 24px;
  }
}

.Database {
  display: flex;
}

.Database-Picker {
  border-bottom-left-radius: $BORDER_RADIUS_CARD;
  border-top-left-radius: $BORDER_RADIUS_CARD;
  min-width: 20%;

  &:not(.Database-Picker--primary-only) + .Database-Database {
    .CardActionBar-container {
      border-top-left-radius: 0;
    }
  }
}

.Database-Picker--primary-only {
  display: none;
}

.Database-Database {
  align-items: stretch;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  // A few things in the data viewer uses position: absolute.
  position: relative;

  .Database-Content {
    outline: none;
    padding: 16px;
    min-height: 400px;

    .Database-Content-Dragging {
      margin: 4px;
      border: 2px dashed rgba(0, 0, 0, 0.54);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.12);

      .invalid {
        color: red;
      }
    }
  }
}
