/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../App/variables';
@import '../common/radius';

.LogViewer {
  display: grid;
  grid-template-areas: 'query' 'history' 'debug';
  grid-template-rows: fit-content(100%) 1fr fit-content(100%);
  height: $app-remaining-viewport-height-for-content;

  .QueryBar {
    grid-area: query;
  }

  #debug {
    border-radius: 10px;
    opacity: 0.2;
    padding: 10px;
  }
}
