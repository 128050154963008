/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.query-panel {
  width: 360px;
}

.button-wrapper {
  display: inline-block;
}

.badge {
  &:after {
    content: ' ';
    width: 16px;
    height: 16px;
    background: var(--mdc-theme-primary);
    position: absolute;
    top: 8px;
    right: 8px;

    border-radius: 100%;
  }
}
