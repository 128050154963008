/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../common/radius';
@import '../common/shadow';

.CardActionBar {
  display: block;

  .CardActionBar-container {
    align-items: center;
    box-shadow: $GMP_SHADOW_DIVIDER_BOTTOM_INSET;
    display: flex;
    height: 56px;
  }

  .CardActionBar-actions {
    display: flex;
    align-items: center;
  }

  &.top {
    .CardActionBar-container {
      border-radius: $BORDER_RADIUS_CARD_TOP;
    }
  }

  &.top-left {
    .CardActionBar-container {
      border-top-left-radius: $BORDER_RADIUS_CARD;
    }
  }

  &.top-right {
    .CardActionBar-container {
      border-top-right-radius: $BORDER_RADIUS_CARD;
    }
  }

  &.bottom {
    .CardActionBar-container {
      border-radius: $BORDER_RADIUS_CARD_BOTTOM;
    }
  }

  &.left {
    .CardActionBar-container {
      border-radius: $BORDER_RADIUS_CARD_LEFT;
    }
  }

  &.bottom-left {
    .CardActionBar-container {
      border-bottom-left-radius: $BORDER_RADIUS_CARD;
    }
  }

  &.bottom-right {
    .CardActionBar-container {
      border-bottom-right-radius: $BORDER_RADIUS_CARD;
    }
  }

  &.right {
    .CardActionBar-container {
      border-radius: $BORDER_RADIUS_CARD_RIGHT;
    }
  }
}

// only first when inside a Card
.mdc-card > .CardActionBar:first-of-type {
  .CardActionBar-container {
    border-radius: $BORDER_RADIUS_CARD_TOP;
  }
}
