/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

$input-default-padding: 8px;
$input-height: 36px;

@use '@material/notched-outline/_index' as notched-outline;
@use '@material/textfield/_index' as textfield with (
  $height: 40px, // wow, cant set to 36px
  $input-padding: $input-default-padding,
  $input-padding-top: $input-default-padding,
  $input-padding-bottom: $input-default-padding,
  $label-offset: $input-default-padding,
  $outlined-idle-border: var(--fire-color-input-borders),
  $outlined-hover-border: var(--fire-color-input-borders),
  $outlined-input-padding-top: $input-default-padding,
  $outlined-input-padding-bottom: $input-default-padding,
);

// TODO: uncomment for 6.0.9, when fixed
// @use '@material/select/_index' as select with (
//   $height: $input-height,
//   $label-padding: $input-default-padding,
//   $arrow-padding: $input-default-padding,
//   $outline-label-offset: $input-default-padding,
//   $outlined-idle-border: var(--fire-color-input-borders),
//   $outlined-hover-border: var(--fire-color-input-borders),
// );

@import './color';
@import './z';

// TODO: remove below for select 6.0.9
$mdc-select-height: $input-height;
$mdc-select-label-padding: $input-default-padding;
$mdc-select-arrow-padding: $input-default-padding;
$mdc-select-outline-label-offset: $input-default-padding;
$mdc-select-outlined-idle-border: var(--fire-color-input-borders);
$mdc-select-outlined-hover-border: var(--fire-color-input-borders);
@import '@material/select/dist/mdc.select';
// @import '@rmwc/select/select'; // 6.0.9, when fixed

// TODO: Cannot use mixins because the mixins deps are 6.0.9 and select is 5.7.2
// @link https://github.com/jamesmfriedman/rmwc/issues/596
.mdc-select {
  height: $input-height;

  &.mdc-select--outlined {
    .mdc-notched-outline {
      @include notched-outline.color($mdc-select-outlined-idle-border);
    }

    &:not(.mdc-select--disabled) {
      &:not(.mdc-select--focused) .mdc-select__native-control:hover ~,
      &:not(.mdc-select--focused) .mdc-select__selected-text:hover ~ {
        .mdc-notched-outline {
          @include notched-outline.color($mdc-select-outlined-hover-border);
        }
      }
    }
  }

  .mdc-select__native-control,
  .mdc-select__selected-text {
    height: $input-height;
    line-height: normal;
    min-width: 0;
    padding: $input-default-padding; // base component doesnt do this yet
  }

  .mdc-select__dropdown-icon,
  .mdc-select__icon {
    bottom: $input-default-padding - 2px; // base component doesnt do this yet
    z-index: $ZINDEX_MEDIUM;
  }
}

@import '@material/textfield/mdc-text-field';

.mdc-text-field {
  @include textfield.height(36px); // lol, but this works
}

.mdc-select--outlined,
.mdc-text-field--outlined {
  .mdc-notched-outline {
    @include notched-outline.floating-label-float-position(85%);

    .mdc-floating-label {
      top: $input-default-padding + 1px; // no var to config
    }
  }
}

.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__native-control,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  // Force white background (instead of transparent, e.g. in dialogs).
  background-color: $COLOR_WHITE_PRIMARY;
  border-radius: 4px; // To avoid "leaking" background color in corners.

  // We also reset the z-index, causing the notched outline to ALWAYS come on
  // top of the input (since the outline will go under otherwise.)
  z-index: auto;

  // Note: We tried and abandoned setting background on .mdc-notched-outline,
  // because Material styles position it ABOVE the input on Chrome autofill,
  // causing input content text to be covered. See b/170908531 (internal) and:
  // https://github.com/material-components/material-components-web/pull/4232/files
}

// Highlight checkboxes when navigating from keyboard
.mdc-data-table__header-row-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
  ~ .mdc-checkbox__background:before,
.mdc-data-table__row-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
  ~ .mdc-checkbox__background:before {
  background-color: rgba(0, 0, 0, 0.54);
}
