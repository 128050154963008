/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import './AppBar_variables';
@import './variables';

$page-gutter-size: 48px;

.App {
  // Mainly to fill the whole screen with background color.
  min-height: 100vh;
}

.App-main {
  padding-top: $app-bar-height + $app-main-real-vert-padding;
  padding-bottom: $app-main-real-vert-padding;
  margin: 0 auto;
  max-width: 1256px + (2 * $page-gutter-size); // offset grid margins
  padding-left: $page-gutter-size;
  padding-right: $page-gutter-size;

  // Make main part at least fill the rest of the viewport.
  // If for any reason this is undesirable for certain routes, use a nested grid
  // like <GridCell span={12}><GridRow>Content</GridRow></GridCell> .
  > .mdc-layout-grid__inner {
    min-height: $app-remaining-viewport-height-for-content;
  }
}
