/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../common/icon';
@import '../common/utils';
@import '../common/a11y';

.BreadCrumbs {
  align-items: center;
  color: var(--mdc-theme-text-secondary-on-background);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 56px;
  list-style: none;
  margin: 0 6px;
  min-width: 0;
  padding: 0;

  @include a11y-display-on-hover('.BreadCrumbs-edit .mdc-icon-button');

  .mdc-icon-button {
    &::before,
    &::after {
      display: none; // remove the hover/ripple
    }

    &:hover {
      color: var(--mdc-theme-text-primary-on-background);
    }
  }

  &:hover {
    .BreadCrumbs-edit {
      .mdc-icon-button {
        visibility: visible;
      }
    }
  }
}

.BreadCrumbs-crumb {
  align-items: center;
  display: inline-flex;
  flex-shrink: 20;
  min-width: 0;
  padding: 0;
  position: relative;
}

.BreadCrumbs-homeCrumb {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  flex-shrink: 0;
}

.BreadCrumbs-link {
  padding-left: 20px;

  > * {
    @include ellipsis;
  }

  :link,
  :visited {
    color: var(--mdc-theme-text-secondary-on-background);
    min-width: 12px; // fix cut off single chars
    text-decoration: none;
  }

  :hover {
    color: var(--mdc-theme-primary);
    text-decoration: underline;
  }

  &::before {
    @include icon('chevron_right', 20px);
  }

  &.BreadCrumbs-active {
    flex-shrink: 1;
  }
}

.BreadCrumbs-edit {
  flex: 1 auto;

  .mdc-icon-button {
    color: var(--mdc-theme-text-primary-on-background);
    font-size: 16px;
    height: 24px;
    padding: 0;
    width: 24px;
  }
}
