/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../../common/color';
@import 'common/mixins';

$name: 'NodeLeaf';

.NodeLeaf {
  @include hoverKey($name);

  align-items: center;
  color: $COLOR_TEXT_SECONDARY;
  display: flex;
  height: 32px;
  padding-left: 38px; // alignment with expand/collapse icons
  position: relative;
  flex-wrap: wrap;

  .#{$name}__key,
  .#{$name}__value {
    font-family: 'Roboto Mono', monospace;
  }

  .#{$name}__value {
    color: $COLOR_TEXT_PRIMARY;
    margin-left: 8px;
    cursor: pointer;
  }

  .#{$name}__actions {
    visibility: hidden;
    flex: 1;
  }

  .#{$name}__edit-ui {
    width: 100%; // force flex-wrap
  }

  .Firestore-InlineEditor-relative-anchor {
    .Firestore-InlineEditor {
      left: -8px;
      right: initial;
      top: -36px; // float over the key being edited
    }
  }

  &:hover > .#{$name}__actions {
    visibility: visible;
  }
}
