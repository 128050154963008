/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.QueryBar {
  display: grid;

  .consistent_text {
    box-sizing: border-box;
    grid-column: 1;
    grid-row: 1;

    border: 1px solid #ddd;
    border-width: 1px 0px 1px 0px;
    border-radius: 4px 4px 0px 0px;

    margin: 0px;
    display: block;

    font-size: 1em;
    font-family: monospace;
    padding: 20px 24px 4px 24px;

    line-height: 1.2em;

    &::placeholder {
      color: #333;
    }

    overflow: hidden;
  }

  .QueryBar-input {
    @extend .consistent_text;
    resize: none;
    background-color: rgba(0, 0, 0, 0);
    caret-color: black;
    color: rgba(0, 0, 0, 0);

    &:focus {
      outline: none;
    }
  }

  .QueryBar-render {
    @extend .consistent_text;
    width: 100%;
    height: 100%;

    color: black;
    margin-bottom: 16px;

    span {
      white-space: pre-wrap;
      &.text {
        color: black;
      }

      &.key {
        color: grey;
      }

      &.value {
        color: var(--mdc-theme-primary);
      }

      &.invalid_pair {
        text-decoration: underline;
        text-decoration-color: red;
      }
    }
  }

  .QueryBar-actions {
    grid-column: 1;
    grid-row: 1;
    justify-self: end;
    align-self: start;
    padding: 10px 10px 10px 0px;
    margin-bottom: 1px;
    display: grid;
    grid-template-columns: repeat(2, fit-content(100%));
    grid-column-gap: 8px;
  }
}
