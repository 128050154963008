/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import './color';

// Divider-style shadow values
$__DIVIDER_COLOR: $COLOR_DIVIDER;
$GMP_SHADOW_DIVIDER_INSET: 0 0 0 1px $__DIVIDER_COLOR inset;
$GMP_SHADOW_DIVIDER_BOTTOM_INSET: 0 -1px 0 $__DIVIDER_COLOR inset;
$GMP_SHADOW_DIVIDER_TOP_INSET: 0 1px 0 $__DIVIDER_COLOR inset;
