/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import './utils';

$ZINDEX_LOW: 1;
$ZINDEX_MEDIUM: 1;
$ZINDEX_SPINNER: 6;
$ZINDEX_FEATURE_BAR: 10;

$ZINDEX_INLINE_EDITOR_SCRIM: 39;
$ZINDEX_INLINE_EDITOR: 40;

$ZINDEX_CRITICAL_ALERTS: 49;
$ZINDEX_ALERTS_DRAWER_SCRIM: 51;
$ZINDEX_ALERTS_DRAWER: 52;

// This should be above almost everything
$ZINDEX_FULL_PAGE_MODAL: 1000;

$ZINDEX_UNDER_TOP: 1000;
$ZINDEX_TOP: 1001;
