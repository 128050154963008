/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../../common/z';

$container-overhang: 8px;

.Firestore-InlineEditor-relative-anchor {
  position: relative;

  .Firestore-InlineEditor {
    position: absolute;
    right: -$container-overhang;
    top: 0;
    z-index: $ZINDEX_INLINE_EDITOR;
  }
}

.Firestore-InlineEditor {
  background-color: var(--mdc-theme-surface);
}

.Firestore-InlineEditorContent {
  padding: 8px 16px;
}

.Firestore-InlineEditorActions {
  justify-content: flex-end;
}
