/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.Firestore-Request-Details-Inspection {
  border-bottom-right-radius: 8px;
  // Shadow border at left
  box-shadow: 1px 0 0 rgba(0, 0, 0, 0.12) inset;
  // Overflow behavior: make the inside content of the inspection section scrollable
  width: 40%;
  position: absolute;
  top: 0px;
  right: 0px;
  min-width: 20%;
  height: 100%;
  overflow: scroll;
  // Take all width on mobile
  @media screen and (max-width: 500px) {
    width: 100%;
    border-bottom-left-radius: 8px;
  }
}

.Firestore-Request-Details-Inspection-Nothing-To-Show {
  padding: 10px;
  font-size: 14px;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
