/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.filterForm {
  width: 100%;

  :global
    .mdc-text-field:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
    input.mdc-text-field__input {
      padding-left: 50px;

      &::placeholder {
        text-overflow: ellipsis;
      }
    }

    // Get rid of material line in the bottom of the input.
    .mdc-line-ripple {
      display: none;
    }
  }
}
