/**
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '../../../common/color';
@import '../../../common/z';

@mixin inlineEditCard($baseName) {
  left: 0;
  margin: 0 20px 0 36px;
  position: absolute;
  right: 0;
  top: 0;

  &.#{$baseName}__card {
    z-index: $ZINDEX_INLINE_EDITOR;
  }

  .#{$baseName}__form-fields {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 1rem;

    .mdc-text-field {
      flex: 1;
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .#{$baseName}__actions {
    justify-content: flex-end;
  }
}

@mixin hoverKey($name) {
  > .#{$name}__key {
    background-color: white;
    border-radius: 4px;
    padding: 0 8px;
    transition: background-color 100ms ease-in;
  }

  &:hover {
    > .#{$name}__key {
      background-color: $COLOR_BACKGROUND_KEY;
    }
  }
}
